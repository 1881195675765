.header {
    background: #232F3E;
    height: 60px;
    padding: 0 23px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .header .logo-img {
    max-width: 170px;
    width: 100%;
    margin-bottom: -3px; }
  .header .logo-text {
    font-size: 30px;
    color: #D8D8D8;
    margin-left: 14px; }
  @media (max-width: 999.98px) {
    .header {
        padding: 0 16px; }
    .header .logo-img {
        max-width: 55px;
        margin-bottom: -8px; }
    .header .logo-text {
        font-size: 14px;
        margin-left: 8px; } }
  
  @media (max-width: 999.98px) {
    .navigation-container {
        padding-right: 0;
        margin-right: -15px;
        position: static; } }
  
  
.amzn-btn {
    background-color: #ff9900!important;
    border: #ff9900 !important;
  
  }
  
  .amzn-btn:hover {

    background-color: #232F3E!important;
    border: #232F3E !important;
    
  }

  